// FeaturesSection.jsx

import React from 'react';
import { FaMagic, FaImage, FaSyncAlt, FaPaintBrush } from 'react-icons/fa'; // Custom icons for unique features
import { motion } from 'framer-motion';

const FeatureCard = ({ icon, title, description }) => {
  return (
    <motion.div
      className="p-8 bg-white rounded-3xl shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="text-orange-500 mb-6 flex justify-center">
        {icon}
      </div>
      <h3 className="text-2xl font-semibold text-gray-900 mb-4 text-center">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </motion.div>
  );
};

const FeaturesSection = () => {
  return (
    <section className="relative py-20 md:py-12 bg-gradient-to-b from-white to-orange-50 overflow-hidden">
      {/* Decorative Elements */}
      {/* <div className="absolute top-0 left-0 w-48 h-48 bg-orange-200 rounded-full mix-blend-multiply opacity-20 filter blur-3xl animate-pulse"></div>
      <div className="absolute bottom-0 right-0 w-64 h-64 bg-red-200 rounded-full mix-blend-multiply opacity-20 filter blur-3xl animate-pulse"></div> */}

      <div className="relative max-w-7xl mx-auto px-6 lg:px-8">
        {/* Heading */}
        <div className="text-center mb-16">
          <motion.h2
            className="text-5xl font-extrabold text-gray-900 mb-4"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
          >
            Cutting-Edge <span className="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-red-500">AI Features</span>
          </motion.h2>
          <motion.p
            className="text-lg text-gray-700"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
          >
            Harness the power of AI to restore, enhance, and transform your images with precision and ease.
          </motion.p>
        </div>

        {/* Feature Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12">
          <FeatureCard
            icon={<FaPaintBrush size={40} aria-hidden="true" />}
            title="AI Image Inpainting"
            description="Seamlessly remove objects, fill in missing areas, and reconstruct damaged images using AI-powered inpainting."
          />
          <FeatureCard
            icon={<FaMagic size={40} aria-hidden="true" />}
            title="AI Restoration"
            description="Restore old or damaged photos, reviving them with AI-driven enhancements that bring your memories back to life."
          />
          <FeatureCard
            icon={<FaSyncAlt size={40} aria-hidden="true" />}
            title="Advanced Upscaling"
            description="Upscale low-resolution images without losing quality, making them perfect for printing or large displays."
          />
          <FeatureCard
            icon={<FaImage size={40} aria-hidden="true" />}
            title="Color & Light Correction"
            description="Automatically adjust colors, brightness, and contrast to give your photos a natural, vivid look."
          />
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
