// Modelbox.jsx

import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import "../../style/modal.css"

const Modelbox = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://gradio.s3-us-west-2.amazonaws.com/4.40.0/gradio.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="bg-gradient-to-br from-orange-50 to-red-50 min-h-screen flex items-center justify-center py-12 md:py-24 px-4 sm:px-6 lg:px-8">
      <div
        className="max-w-5xl w-full bg-white rounded-3xl shadow-2xl p-2 md:p-10 relative overflow-hidden"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="relative z-10">
          <motion.div
            className="text-center mb-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-4xl md:text-5xl font-extrabold text-orange-600 opacity-80">
            Upgrade your images with Inpainting
            </h2>
            <p className="mt-4 text-lg md:max-w-3xl mx-auto text-gray-600">
              Upload your image and let AI change your photo clarity, colors, and details in seconds. Experience the next level of image transformation.
            </p>
            <p className="mt-4 text-sm text-red-300">  
              <span className="font-semibold">Note:</span> The model may take a few seconds to load. Please be patient.
            </p>
          </motion.div>

          <div className="relative w-full z-10 mt-12">
            <gradio-app
              src="https://rol-box-gsdee.hf.space"
            ></gradio-app>
          </div>
        </div> 
      </div>
      </div>
    );
  };

  export default Modelbox;
