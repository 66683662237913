import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { encryptData } from '../utils/encryption';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => !!localStorage.getItem('token'));
  const navigate = useNavigate();  

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const login = (res, previous_url) => {
    localStorage.setItem('users', encryptData(res.user));
    localStorage.setItem('token', encryptData(res.access));
    localStorage.setItem('refresh', encryptData(res.refresh));
    setIsAuthenticated(true);
    if(previous_url === '/') {
      navigate('/');
    } else {
      localStorage.removeItem('previous_url');
      navigate('/model-box');
    }
  };

  const logout = () => {
    localStorage.removeItem('users');
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    setIsAuthenticated(false);
    navigate('/signin');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
