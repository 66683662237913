// ContactDetails.jsx

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaUsers } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactDetails = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      // Simulate form submission (replace with actual API call)
      await new Promise((resolve) => setTimeout(resolve, 2000));
      toast.success('Your message has been sent successfully!');
      reset();
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="relative py-20 bg-gradient-to-r from-orange-50 to-red-50 overflow-hidden">
      {/* Decorative Blobs */}
      <div className="absolute top-0 left-0 w-48 h-48 bg-orange-200 rounded-full mix-blend-multiply opacity-30 filter blur-3xl animate-blob"></div>
      <div className="absolute bottom-0 right-0 w-64 h-64 bg-red-200 rounded-full mix-blend-multiply opacity-30 filter blur-3xl animate-blob animation-delay-2000"></div>

      {/* Main Content */}
      <div className="relative max-w-7xl mx-auto px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          
          {/* Contact Information Cards */}
          <div className="space-y-6">
            <motion.h2
              className="text-4xl font-extrabold text-gray-800 mb-8"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              Get in Touch
            </motion.h2>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {/* Office Location */}
              <motion.div
                className="bg-white shadow-md p-6 rounded-xl flex items-start hover:shadow-lg transition-shadow duration-300"
                whileHover={{ scale: 1.02 }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.8 }}
              >
                <FaMapMarkerAlt className="w-8 h-8 text-orange-500 flex-shrink-0" aria-hidden="true" />
                <div className="ml-4">
                  <h4 className="text-xl font-semibold text-gray-700">Office Location</h4>
                  <p className="text-gray-600 mt-2">16122 Collins Street, Melbourne, Australia</p>
                </div>
              </motion.div>

              {/* Email */}
              <motion.div
                className="bg-white shadow-md p-6 rounded-xl flex items-start hover:shadow-lg transition-shadow duration-300"
                whileHover={{ scale: 1.02 }}
                initial={{ opacity: 0, y: 60 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 0.8 }}
              >
                <FaEnvelope className="w-8 h-8 text-orange-500 flex-shrink-0" aria-hidden="true" />
                <div className="ml-4">
                  <h4 className="text-xl font-semibold text-gray-700">Email Us</h4>
                  <p className="text-gray-600 mt-2">info@picturify.ai</p>
                  <p className="text-gray-600">support@picturify.ai</p>
                </div>
              </motion.div>

              {/* Phone */}
              <motion.div
                className="bg-white shadow-md p-6 rounded-xl flex items-start hover:shadow-lg transition-shadow duration-300"
                whileHover={{ scale: 1.02 }}
                initial={{ opacity: 0, y: 70 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6, duration: 0.8 }}
              >
                <FaPhoneAlt className="w-8 h-8 text-orange-500 flex-shrink-0" aria-hidden="true" />
                <div className="ml-4">
                  <h4 className="text-xl font-semibold text-gray-700">Call Us</h4>
                  <p className="text-gray-600 mt-2">1-800-555-1234</p>
                  <p className="text-gray-600">1-800-555-5678</p>
                </div>
              </motion.div>

              {/* Join Our Team */}
              <motion.div
                className="bg-white shadow-md p-6 rounded-xl flex items-start hover:shadow-lg transition-shadow duration-300"
                whileHover={{ scale: 1.02 }}
                initial={{ opacity: 0, y: 80 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8, duration: 0.8 }}
              >
                <FaUsers className="w-8 h-8 text-orange-500 flex-shrink-0" aria-hidden="true" />
                <div className="ml-4">
                  <h4 className="text-xl font-semibold text-gray-700">Join Our Team</h4>
                  <p className="text-gray-600 mt-2">careers@picturify.ai</p>
                </div>
              </motion.div>
            </div>
          </div>

          {/* Contact Form */}
          <motion.div
            className="bg-white p-8 md:p-10 rounded-xl shadow-lg"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.8 }}
          >
            <h3 className="text-3xl font-semibold text-gray-800 mb-6">Send Us a Message</h3>
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-600">Name<span className="text-red-500">*</span></label>
                  <input
                    id="name"
                    type="text"
                    {...register('name', { required: 'Name is required' })}
                    placeholder="Your Name"
                    className={`w-full px-4 py-3 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent`}
                    aria-invalid={errors.name ? "true" : "false"}
                    aria-describedby="name-error"
                  />
                  {errors.name && (
                    <span className="text-red-500 text-sm mt-1" id="name-error">
                      {errors.name.message}
                    </span>
                  )}
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-600">Email<span className="text-red-500">*</span></label>
                  <input
                    id="email"
                    type="email"
                    {...register('email', { 
                      required: 'Email is required',
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: 'Entered value does not match email format'
                      }
                    })}
                    placeholder="Your Email"
                    className={`w-full px-4 py-3 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent`}
                    aria-invalid={errors.email ? "true" : "false"}
                    aria-describedby="email-error"
                  />
                  {errors.email && (
                    <span className="text-red-500 text-sm mt-1" id="email-error">
                      {errors.email.message}
                    </span>
                  )}
                </div>
              </div>

              <div>
                <label htmlFor="subject" className="block text-sm font-medium text-gray-600">Subject</label>
                <input
                  id="subject"
                  type="text"
                  {...register('subject')}
                  placeholder="Subject"
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                />
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-600">Message<span className="text-red-500">*</span></label>
                <textarea
                  id="message"
                  {...register('message', { required: 'Message is required' })}
                  placeholder="Write your message"
                  className={`w-full h-32 px-4 py-3 border ${errors.message ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent`}
                  aria-invalid={errors.message ? "true" : "false"}
                  aria-describedby="message-error"
                ></textarea>
                {errors.message && (
                  <span className="text-red-500 text-sm mt-1" id="message-error">
                    {errors.message.message}
                  </span>
                )}
              </div>

              <button
                type="submit"
                className={`w-full flex items-center justify-center px-6 py-3 bg-gradient-to-r from-orange-500 to-red-500 text-white font-semibold rounded-lg shadow-md hover:from-orange-600 hover:to-red-600 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending...' : 'Send Message'}
              </button>
            </form>
          </motion.div>
        </div>

        {/* Toast Notifications */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        </div>
      </section>
    );
  };
  
  export default ContactDetails;
