import React from 'react';
import UseCasesSection from './portfolio/PortfolioSection';

const Portfolio = () => {
  return (
    <> 
      <UseCasesSection />
    </>
  );
};

export default Portfolio;
