import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../Frontend/page/Home';
import About from '../Frontend/page/About';
import Contact from '../Frontend/page/Contact';
import SigninPage from '../Frontend/page/Auth/Signin'; 
import SignupPage from '../Frontend/page/Auth/Signup';
import FrontendLayout from '../Layouts/FrontendLayout';
import ProtectedRoute from './ProtectedRoute'; 
import Portfolio from '../Frontend/page/portfolio';
import Modelbox from '../Frontend/page/Modelbox';

const RouteList = () => {
  return (
    <Routes>
      <Route element={<FrontendLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/signin" element={<SigninPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/model-box" element={<ProtectedRoute element={<Modelbox />} />} />
      </Route>
    </Routes>
  );
};

export default RouteList;
