import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../Frontend/Footer/footer';
import Header from '../Frontend/Navbar/Header';

const FrontendLayout = ({ children }) => {
  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default FrontendLayout;
