import React from 'react';
import AboutUsSection from './About/AboutUsSection';
import CallToActionSection from './Home/Calltoactionsection';
import TestimonialSection from './Home/Testimonialsection';
import FeaturesSection from './About/FeaturesSection';

const About = () => {
  return (
    <> 
      <AboutUsSection />
      <FeaturesSection />
      <TestimonialSection /> 
      <CallToActionSection />
    </>
  );
};

export default About;
