// AboutUsSection.jsx

import React from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { FaStar, FaCheckCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';

const AboutUsSection = () => {
  return (
    <section className="relative py-20 md:pt-32 bg-gradient-to-b from-orange-50 to-white overflow-hidden">
      {/* Background Accent for Unique Design */}
      {/* <div className="absolute inset-0 bg-gradient-to-r from-orange-100 to-red-100 opacity-20 pointer-events-none"></div> */}

      {/* Main Content Wrapper */}
      <div className="relative max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 px-6 lg:px-8 items-center">
        
        {/* Left Side - Content Section */}
        <div className="md:pr-8 z-10">
          {/* Label */}
          <div className="inline-flex items-center bg-orange-100 text-orange-700 font-semibold py-1 px-4 rounded-full text-sm mb-4">
            <FaCheckCircle className="mr-2" />
            About Picturify.AI
          </div>

          {/* Headline */}
          <motion.h2
            className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-6 leading-tight"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Reimagine Your Memories with <span className="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-red-500">AI Inpainting</span> Technology
          </motion.h2>

          {/* Subheadline */}
          <motion.p
            className="text-lg text-gray-700 mb-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
          >
            At Picturify.AI, we specialize in reviving and restoring your most precious memories. Using advanced AI-powered inpainting, we can effortlessly remove imperfections, reconstruct damaged areas, and enhance the overall quality of your photos.
          </motion.p>

          {/* Ratings and Social Proof */}
          <motion.div
            className="flex items-center space-x-2 mb-6"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.8 }}
          >
            <div className="flex items-center text-yellow-400">
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
            <span className="text-gray-700 font-semibold">Rated 5 Stars by 50,000+ Users Worldwide</span>
          </motion.div>

          {/* Feature Highlights */}
          <motion.ul
            className="list-disc list-inside text-gray-700 space-y-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.2, duration: 0.8 }}
          >
            <li className="flex items-center">
              <FaCheckCircle className="text-orange-500 mr-2" />
              Seamless restoration of old and damaged photos
            </li>
            <li className="flex items-center">
              <FaCheckCircle className="text-orange-500 mr-2" />
              AI-powered object removal and background reconstruction
            </li>
            <li className="flex items-center">
              <FaCheckCircle className="text-orange-500 mr-2" />
              Instant image enhancements with just one click
            </li>
            <li className="flex items-center">
              <FaCheckCircle className="text-orange-500 mr-2" />
              100% secure and user-friendly platform
            </li>
          </motion.ul>
        </div>

        {/* Right Side - Image Comparison Section */}
        <div className="relative z-10">
          <motion.div
            className="rounded-xl overflow-hidden shadow-xl transform hover:scale-105 transition-transform duration-500"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 1.5, duration: 0.8 }}
          >
            <ReactCompareSlider
              itemOne={<ReactCompareSliderImage src="./images/img-inpaint/14.webp" alt="Before Image" />}
              itemTwo={<ReactCompareSliderImage src="./images/img-inpaint/14c.webp" alt="After Image" />}
              style={{
                borderRadius: '0.75rem',
              }}
            />
            <div className="text-center mt-2 text-gray-500">
              <span className="text-sm font-semibold">Before</span> vs <span className="text-sm font-semibold">After</span>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Decorative Elements */}
      <div className="absolute top-10 left-0 w-32 h-32 bg-orange-200 rounded-full mix-blend-multiply opacity-30 filter blur-3xl animate-pulse"></div>
      <div className="absolute bottom-10 right-0 w-48 h-48 bg-red-200 rounded-full mix-blend-multiply opacity-30 filter blur-3xl animate-pulse"></div>
    </section>
  );
};

export default AboutUsSection;
