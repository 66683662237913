import React from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { FaMagic, FaPalette, FaCamera } from 'react-icons/fa';

const PhotoChangerSection = () => {
  return (
    <section className="py-16 md:py-32 bg-gradient-to-b from-orange-50 to-white">
      <div className="max-w-7xl mx-auto px-6 sm:px-12 lg:px-16">

        {/* Section Header */}
        <div className="text-center mb-20">
          <h2 className="text-4xl md:text-6xl font-extrabold text-gray-900">
            Transform Your Photos with <span className="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-red-500">AI Precision</span>
          </h2>
          <p className="mt-4 text-lg md:text-xl text-gray-600">
            Experience the next level of photo enhancement. From clarity to color, our AI-powered tools refine every detail.
          </p>
        </div>

        {/* Feature Sections */}
        <div className="space-y-24">
          
          {/* Feature 1 */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="rounded-xl overflow-hidden shadow-xl transform transition duration-500 hover:scale-105">
              <ReactCompareSlider
                itemOne={<ReactCompareSliderImage src="./images/img-inpaint/3.webp" alt="Original Image" />}
                itemTwo={<ReactCompareSliderImage src="./images/img-inpaint/3c.webp" alt="Enhanced Image" />}
                style={{
                  borderRadius: '0.75rem',
                }}
              />
              <div className="text-center mt-3 text-gray-700 font-medium">
                <span className="text-sm font-semibold">Original</span> vs <span className="text-sm font-semibold">Enhanced</span>
              </div>
            </div>
            <div>
              <h3 className="text-3xl md:text-5xl font-bold text-gray-900 mb-4">
                Supercharge Your Photos with <span className="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-red-500">AI Precision</span>
              </h3>
              <p className="text-lg text-gray-700 mb-4">
                Utilize our advanced AI tools to breathe life into your photos. Instantly enhance colors, eliminate blurs, and add depth, making your images truly stand out.
              </p>
              <p className="text-lg text-gray-700">
                With a single click, transform ordinary photos into crystal-clear masterpieces, perfect for both personal memories and professional showcases.
              </p>
            </div>
          </div>

          {/* Feature 2 */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <h3 className="text-3xl md:text-5xl font-bold text-gray-900 mb-4">
                No More Compromise on <span className="text-red-500">Quality</span>
              </h3>
              <p className="text-lg text-gray-700 mb-4">
                Our AI technology meticulously preserves every detail, allowing you to upscale your images without losing clarity. Ideal for high-quality prints or vibrant online sharing.
              </p>
              <p className="text-lg text-gray-700">
                Whether it's old, grainy photos or digital images needing enhancement, Picturify.AI delivers high-definition results every time.
              </p>
            </div>
            <div className="rounded-xl overflow-hidden shadow-xl transform transition duration-500 hover:scale-105">
              <ReactCompareSlider
                itemOne={<ReactCompareSliderImage src="./images/img-inpaint/2.webp" alt="Original Image" />}
                itemTwo={<ReactCompareSliderImage src="./images/img-inpaint/2c.webp" alt="Enhanced Image" />}
                style={{
                  borderRadius: '0.75rem',
                }}
              />
              <div className="text-center mt-3 text-gray-700 font-medium">
                <span className="text-sm font-semibold">Original</span> vs <span className="text-sm font-semibold">Enhanced</span>
              </div>
            </div>
          </div> 

          {/* Feature 4 */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="rounded-xl overflow-hidden shadow-xl transform transition duration-500 hover:scale-105">
              <ReactCompareSlider
                itemOne={<ReactCompareSliderImage src="./images/img-inpaint/1.webp" alt="Before Image" />}
                itemTwo={<ReactCompareSliderImage src="./images/img-inpaint/1cc.webp" alt="After Image" />}
                style={{
                  borderRadius: '0.75rem',
                }}
              />
              <div className="text-center mt-3 text-gray-700 font-medium">
                <span className="text-sm font-semibold">Original</span> vs <span className="text-sm font-semibold">Enhanced</span>
              </div>
            </div>
            <div>
              <h3 className="text-3xl md:text-5xl font-bold text-gray-900 mb-4">
                Perfect Your <span className="text-red-500">Portraits</span> Every Time
              </h3>
              <p className="text-lg text-gray-700 mb-4">
                Enhance portraits like never before with Picturify.AI. Our AI-powered tools correct lighting, reduce noise, and sharpen details to make every photo shine.
              </p>
              <p className="text-lg text-gray-700">
                Whether capturing professional headshots or cherished family memories, Picturify.AI helps you create perfect portraits every time.
              </p>
            </div> 
            </div>
          </div>


        {/* Call to Action */}
        <div className="text-center mt-24">
          <a
            href="#"
            className="inline-block px-10 py-4 bg-gradient-to-r from-orange-500 to-red-500 text-white text-lg font-semibold rounded-full shadow-lg transform transition-transform duration-300 hover:scale-105 hover:from-orange-600 hover:to-red-600"
          >
            Start Enhancing Now
          </a>
        </div>

      </div>
    </section>
  );
};

export default PhotoChangerSection;
