import React from 'react';
import { FaStar } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay'; 

const TestimonialCard = ({ name, text, rating, avatar, isHighlighted = false }) => {
  return (
    <div
      className={`p-8 rounded-3xl shadow-2xl transition-transform duration-300 transform hover:scale-105 ${
        isHighlighted
          ? 'bg-gradient-to-r from-orange-500 to-red-500 text-white'
          : 'bg-white text-gray-700'
      }`}
    >
      {/* User Avatar */}
      <div className="flex items-center justify-center mb-6">
        <img
          src={avatar}
          alt={`${name}'s avatar`}
          className="w-16 h-16 rounded-full border-4 border-orange-500 object-cover"
        />
      </div>

      {/* User Name */}
      <h4 className="text-2xl font-bold mb-2 text-center">{name}</h4>

      {/* Rating */}
      <div className="flex justify-center mb-4">
        {[...Array(5)].map((_, i) => (
          <FaStar key={i} className={`mr-1 ${i < rating ? 'text-yellow-400' : 'text-gray-300'}`} />
        ))}
      </div>

      {/* Testimonial Text */}
      <p className="text-center text-lg">
        {text}
      </p>
    </div>
  );
};

const TestimonialSection = () => {
  const testimonials = [
    {
      name: 'Emily Johnson',
      text: 'Picturify.AI saved my old family photos! The inpainting tool seamlessly filled in damaged areas and made them look brand new. I was so emotional seeing the restored version of a photo from my childhood.',
      rating: 5,
      avatar: 'https://randomuser.me/api/portraits/women/19.jpg',
    },
    {
      name: 'Michael Smith',
      text: 'The results were incredible!',
      rating: 5,
      avatar: 'https://randomuser.me/api/portraits/men/22.jpg',
    },
    {
      name: 'Sophia Lee',
      text: 'I tried restoring an old, torn photo, and it now looks like it was taken yesterday. The results are nothing short of magical! Picturify.AI’s inpainting tool is unlike anything else I’ve used before. As someone who has tried multiple restoration tools, this one is leagues ahead. The ease of use and the high quality of results make it my go-to for all my photo restoration needs.',
      rating: 5,
      avatar: 'https://randomuser.me/api/portraits/women/45.jpg',
      isHighlighted: true, // Highlighted Testimonial
    },
    {
      name: 'David Brown',
      text: 'As a professional photographer, the ability to repair imperfections in my photos has been a game-changer. Whether it’s removing unwanted objects or enhancing low-quality images, Picturify.AI fits right into my workflow, helping me deliver even better results to my clients.',
      rating: 5,
      avatar: 'https://randomuser.me/api/portraits/men/55.jpg',
    },
    {
      name: 'Olivia Garcia',
      text: 'Even with zero technical experience, I was able to fix and enhance my photos in seconds. The interface is so intuitive, and the results speak for themselves!',
      rating: 5,
      avatar: 'https://randomuser.me/api/portraits/women/77.jpg',
    },
    {
      name: 'Daniel Martinez',
      text: 'The AI inpainting tool managed to remove watermarks and even fill in missing parts seamlessly. Absolutely stunning results! This has saved me so much time and effort that I can now focus on creating more content for my business.',
      rating: 5,
      avatar: 'https://randomuser.me/api/portraits/men/66.jpg',
    },
  ];

  return (
    <section className="bg-gradient-to-b from-orange-50 to-white py-16 md:py-32">
      <div className="max-w-7xl mx-auto px-6 sm:px-12 lg:px-16">
        {/* Section Header */}
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-6xl font-extrabold text-gray-900 mb-4">
            What Our Users <span className="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-red-500">Say</span>
          </h2>
          <p className="text-lg md:text-xl text-gray-600">
            Join thousands of satisfied users who have transformed their photos with Picturify.AI.
          </p>
        </div>

        {/* Testimonials Carousel */}
        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          loop
          className="testimonial-swiper bg-white rounded-3xl "
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <TestimonialCard
                name={testimonial.name}
                text={testimonial.text}
                rating={testimonial.rating}
                avatar={testimonial.avatar}
                isHighlighted={testimonial.isHighlighted}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Call to Action */}
        <div className="text-center mt-16">
          <a
            href="#"
            className="inline-block px-8 py-3 bg-gradient-to-r from-orange-500 to-red-500 text-white text-lg font-semibold rounded-full shadow-lg transform transition duration-300 hover:scale-105 hover:from-orange-600 hover:to-red-600"
          >
            Start Enhancing Now
          </a>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
