import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-400 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Top Section */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          
          {/* Section 1: Brand and About */}
          <div>
            <Link to="/" className="flex items-center space-x-3 mb-4">
              <img src="/images/logo.png" className="h-12" alt="Picturify.AI Logo" />
              <span className="text-2xl font-extrabold text-white">Picturify.AI</span>
            </Link>
            <p className="text-gray-500 text-sm leading-relaxed">
              Picturify.AI brings your photos back to life using advanced AI inpainting and restoration technology. Perfect for reviving old, damaged, or low-quality images.
            </p>
          </div>

          {/* Section 2: Quick Links */}
          <div>
            <h2 className="text-xl font-semibold text-white mb-4">Quick Links</h2>
            <ul className="space-y-2">
              <li>
                <Link to="/about" className="hover:text-orange-500 transition-colors">About Us</Link>
              </li>
              <li>
                <Link to="/features" className="hover:text-orange-500 transition-colors">Features</Link>
              </li>
              <li>
                <Link to="/pricing" className="hover:text-orange-500 transition-colors">Pricing</Link>
              </li>
              <li>
                <Link to="/contact" className="hover:text-orange-500 transition-colors">Contact</Link>
              </li>
            </ul>
          </div>

          {/* Section 3: Resources */}
          <div>
            <h2 className="text-xl font-semibold text-white mb-4">Resources</h2>
            <ul className="space-y-2">
              <li>
                <Link to="/blog" className="hover:text-orange-500 transition-colors">Blog</Link>
              </li>
              <li>
                <Link to="/faq" className="hover:text-orange-500 transition-colors">FAQs</Link>
              </li>
              <li>
                <Link to="/privacy" className="hover:text-orange-500 transition-colors">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms" className="hover:text-orange-500 transition-colors">Terms of Service</Link>
              </li>
            </ul>
          </div>

          {/* Section 4: Newsletter and Social */}
          <div>
            <h2 className="text-xl font-semibold text-white mb-4">Stay Updated</h2>
            <p className="text-gray-500 text-sm mb-4">
              Subscribe to our newsletter to get the latest updates on AI inpainting and photo restoration tools.
            </p>
            <form className="flex items-center space-x-2 mb-6">
              <input
                type="email"
                className="w-full px-4 py-2 text-sm bg-gray-800 border-none rounded-full text-gray-400 focus:ring-2 focus:ring-orange-500"
                placeholder="Your email"
              />
              <button className="px-6 py-2 bg-gradient-to-r from-orange-500 to-red-500 text-white font-semibold rounded-full hover:bg-orange-600 transition-colors">
                Subscribe
              </button>
            </form>

            {/* Social Media Links */}
            <div className="flex space-x-4">
              <a href="#" className="text-gray-400 hover:text-orange-500 transition-colors">
                <FaFacebookF size={20} />
              </a>
              <a href="#" className="text-gray-400 hover:text-orange-500 transition-colors">
                <FaTwitter size={20} />
              </a>
              <a href="#" className="text-gray-400 hover:text-orange-500 transition-colors">
                <FaInstagram size={20} />
              </a>
              <a href="#" className="text-gray-400 hover:text-orange-500 transition-colors">
                <FaLinkedinIn size={20} />
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-12 border-t border-gray-800 pt-6 flex flex-col md:flex-row justify-between items-center">
          <p className="text-gray-500 mb-4 md:mb-0">&copy; {currentYear} Picturify.AI. All Rights Reserved.</p>
          <div className="text-gray-400">
            <span className="text-sm">Designed for seamless image restoration and enhancement.</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
