// SigninPage.jsx

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from '../../../Common/AuthProvider';
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SigninPage = () => {
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const characterLimits = {
    email: 100,
    password: 20,
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(-1); // Redirect to the previous page if already logged in
    }
  }, [isAuthenticated, navigate]);

  // Validation Logic
  const validate = () => {
    const errors = {};
    if (!email) {
      errors.email = 'Email is required.';
    } else if (email.length > characterLimits.email) {
      errors.email = `Email must be less than ${characterLimits.email} characters.`;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email address is invalid.';
    }
    if (!password) {
      errors.password = 'Password is required.';
    } else if (password.length > characterLimits.password) {
      errors.password = `Password must be less than ${characterLimits.password} characters.`;
    }
    return errors;
  };

  // Handle the form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
      const storedUsers = JSON.parse(localStorage.getItem('users')) || [];
      const user = storedUsers.find((u) => u.email === email && u.password === password);

      if (user) {
        const token = Math.random().toString(36).substr(2); // Generate random token
        localStorage.setItem('authToken', token);
        localStorage.setItem('currentUser', JSON.stringify(user));
        login(token); // Call login function from AuthProvider
        toast.success('Logged in successfully!');
        navigate('/model-box'); // Redirect to homepage after successful login
      } else {
        setErrors({ general: 'Invalid email or password.' });
        toast.error('Invalid email or password.');
      }
      setIsSubmitting(false);
    }
  };

  const handleGoogleSuccess = (response) => {
    const token = response.credential;
    const decoded = jwtDecode(token);

    const googleUser = {
      email: decoded.email,
      full_name: decoded.name,
    };

    const existingUsers = JSON.parse(localStorage.getItem('users')) || [];
    if (!existingUsers.find((u) => u.email === googleUser.email)) {
      existingUsers.push({ email: googleUser.email, password: '', full_name: googleUser.full_name });
      localStorage.setItem('users', JSON.stringify(existingUsers));
    }

    const authToken = Math.random().toString(36).substr(2); // Generate random token
    localStorage.setItem('authToken', authToken);
    localStorage.setItem('currentUser', JSON.stringify(googleUser));
    login(authToken); // Call login function from AuthProvider
    toast.success('Logged in with Google!');
    navigate('/model-box'); // Redirect to homepage
  };

  const handleGoogleFailure = (error) => {
    console.error('Google Login Failed:', error);
    toast.error('Google login failed. Please try again.');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-orange-100 to-yellow-100 py-12 md:py-24 px-4 sm:px-6 lg:px-8">
      <motion.div
        className="max-w-lg w-full bg-white rounded-2xl shadow-xl p-10"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8 }}
      >
        {/* Section Header */}
        <div className="text-center mb-8">
          <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900">
            Log in to <span className="text-orange-500">Picturify.AI</span>
          </h2>
          <p className="text-gray-600 mt-2">
            Or{' '}
            <Link to="/signup" className="text-orange-500 hover:underline">
              create a new account
            </Link>
          </p>
        </div>

        {/* Login Form */}
        <form className="space-y-6" onSubmit={handleSubmit}>
          {/* Email Input */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.6 }}
          >
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email<span className="text-red-500">*</span>
            </label>
            <input
              id="email"
              name="email"
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`mt-1 block w-full px-5 py-3 border ${
                errors.email ? 'border-red-500' : 'border-gray-300'
              } rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent`}
              maxLength={characterLimits.email}
              aria-invalid={errors.email ? 'true' : 'false'}
              aria-describedby="email-error"
              placeholder="Your Email"
            />
            {errors.email && (
              <p className="text-red-500 text-xs mt-2" id="email-error">
                {errors.email}
              </p>
            )}
          </motion.div>

          {/* Password Input */}
          <motion.div
            className="relative"
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.6 }}
          >
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password<span className="text-red-500">*</span>
            </label>
            <input
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`mt-1 block w-full px-5 py-3 border ${
                errors.password ? 'border-red-500' : 'border-gray-300'
              } rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent`}
              maxLength={characterLimits.password}
              aria-invalid={errors.password ? 'true' : 'false'}
              aria-describedby="password-error"
              placeholder="Your Password"
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 right-0 pt-6 pr-3 flex items-center text-gray-500 cursor-pointer"
              aria-label={showPassword ? 'Hide password' : 'Show password'}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
            {errors.password && (
              <p className="text-red-500 text-xs mt-2" id="password-error">
                {errors.password}
              </p>
            )}
          </motion.div>

          {/* Remember Me */}
          <motion.div
            className="flex items-center justify-between"
            initial={{ opacity: 0, y: 60 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6, duration: 0.6 }}
          >
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 text-orange-500 border-gray-300 rounded focus:ring-2 focus:ring-orange-500"
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                Remember me
              </label>
            </div>
          </motion.div>

          {/* Submit Button */}
          <motion.div
            initial={{ opacity: 0, y: 80 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8, duration: 0.6 }}
          >
            <button
              type="submit"
              className={`w-full flex items-center justify-center px-5 py-3 bg-gradient-to-r from-orange-500 to-red-500 text-white text-lg font-semibold rounded-lg shadow-md hover:from-orange-600 hover:to-red-600 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 ${
                isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Logging in...' : 'Log in'}
            </button>
          </motion.div>

          {/* General Error */}
          {errors.general && (
            <motion.p
              className="text-red-500 text-center text-sm mt-2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1, duration: 0.6 }}
            >
              {errors.general}
            </motion.p>
          )}
        </form>

        {/* Divider */}
        <motion.div
          className="flex items-center my-6"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 1.2, duration: 0.6 }}
        >
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="mx-4 text-gray-500 font-semibold">OR</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </motion.div>

        {/* Google Login */}
        <motion.div
          className="w-full flex justify-center"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1.4, duration: 0.6 }}
        >
          <GoogleOAuthProvider clientId="255910356358-lef7m6bhgaknak1u8h9u4bp2aforg4kg.apps.googleusercontent.com">
            <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onFailure={handleGoogleFailure}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className="w-full flex items-center justify-center py-3 px-4 border border-gray-300 rounded-lg bg-white shadow-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500"
                  aria-label="Sign in with Google"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                    alt="Google Logo"
                    className="w-6 h-6 mr-2"
                  />
                  <span className="text-gray-700">Sign in with Google</span>
                </button>
              )}
            />
          </GoogleOAuthProvider>
        </motion.div>

        {/* Toast Notifications */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </motion.div>
    </div>
  );
};

export default SigninPage;
