import { combineReducers, configureStore } from '@reduxjs/toolkit';
 
const rootReducer = combineReducers({
});

const initialState = {
    data: [], 
};

const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
});

export default store;
