// HeroSection.jsx

import React from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { motion } from 'framer-motion';
import { FaArrowRight } from 'react-icons/fa';

const HeroSection = () => {
  return (
    <section className="relative overflow-hidden bg-gradient-to-b from-orange-50 to-yellow-100 py-12 md:py-24">
      {/* Background Design */}
      <div className="absolute inset-0">
        <div className="absolute left-0 top-0 w-64 h-64 bg-orange-200 rounded-full mix-blend-multiply filter blur-2xl opacity-50 animate-pulse"></div>
        <div className="absolute right-0 bottom-0 w-96 h-96 bg-yellow-300 rounded-full mix-blend-multiply filter blur-2xl opacity-50 animate-pulse"></div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="flex flex-col-reverse lg:flex-row items-center justify-between">
          {/* Image Comparison Slider */}
          <motion.div
            className="mt-12 lg:mt-0 w-full lg:w-1/2"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <div className="w-full h-64 md:h-5/6 rounded-xl overflow-hidden shadow-2xl transform transition duration-500 hover:scale-105">
              <ReactCompareSlider
                itemOne={
                  <ReactCompareSliderImage
                    src="./images/img-inpaint/12c.webp"
                    alt="Original Image"
                    className="object-cover w-full h-full"
                  />
                }
                itemTwo={
                  <ReactCompareSliderImage
                    src="./images/img-inpaint/12ccc.webp"
                    alt="Enhanced Image"
                    className="object-cover w-full h-full"
                  />
                }
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '0.75rem', // Rounded-xl
                }}
              />
            </div>
          </motion.div>

          {/* Text Section */}
          <motion.div
            className="lg:max-w-lg text-left"
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-4xl md:text-6xl font-extrabold text-gray-900 leading-tight">
              Unleash the Potential of Your Photos
            </h1>
            <motion.h2
              className="text-3xl md:text-5xl mt-2 font-bold text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-red-500"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.8 }}
            >
              With Picturify.AI
            </motion.h2>
            <motion.p
              className="text-lg md:text-xl text-gray-700 mt-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.8 }}
            >
              Elevate your images instantly using our cutting-edge AI technology. Enhance colors, improve clarity, and bring your photos to life with just a single click.
            </motion.p>

            {/* Call to Action Buttons */}
            <motion.div
              className="mt-8 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.9, duration: 0.8 }}
            >
              <a
                href="#"
                className="inline-flex items-center justify-center px-8 py-4 bg-gradient-to-r from-orange-500 to-red-500 text-white text-lg font-semibold rounded-full shadow-lg transform transition duration-300 hover:scale-105 hover:from-orange-600 hover:to-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                aria-label="Get Started"
              >
                Get Started
                <FaArrowRight className="ml-2" />
              </a>
              <a
                href="#features"
                className="inline-flex items-center justify-center px-8 py-4 bg-white text-orange-600 border border-orange-500 text-lg font-semibold rounded-full shadow-lg transform transition duration-300 hover:scale-105 hover:bg-orange-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-300"
                aria-label="Learn More"
              >
                Learn More
                <FaArrowRight className="ml-2" />
              </a>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
