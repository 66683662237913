// HowToEnhanceSection.jsx

import React from 'react';
import { FaUpload, FaBrush, FaDownload, FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';

const HowToEnhanceSection = () => {
  return (
    <section className="py-16 md:py-28 bg-gradient-to-r from-orange-50 to-red-50 relative overflow-hidden">
      {/* Decorative Blobs */}
      <div className="absolute top-0 left-0 w-48 h-48 bg-orange-200 rounded-full mix-blend-multiply opacity-30 filter blur-3xl animate-blob"></div>
      <div className="absolute bottom-0 right-0 w-64 h-64 bg-red-300 rounded-full mix-blend-multiply opacity-30 filter blur-3xl animate-blob animation-delay-2000"></div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-6 sm:px-12 relative z-10">
        {/* Section Header */}
        <motion.div
          className="text-center mb-16"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="text-4xl md:text-6xl font-extrabold text-gray-800">
            Enhance Your Photos with Ease
          </h2>
          <p className="mt-4 text-lg md:text-xl text-gray-600">
            Follow these simple steps to transform your photos using our AI-powered tools.
          </p>
        </motion.div>

        {/* Steps */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              className="relative bg-white rounded-3xl shadow-xl p-8 transition-transform duration-300 hover:-translate-y-2"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 * index, duration: 0.8 }}
              whileHover={{ scale: 1.05 }}
            >
              {/* Icon */}
              <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 bg-gradient-to-r from-orange-500 to-red-500 rounded-full p-4 shadow-lg">
                <div className="text-white text-2xl">
                  {step.icon}
                </div>
              </div>

              {/* Image */}
              <div className="mt-8">
                <img
                  src={step.image}
                  alt={step.title}
                  className="w-full h-48 object-cover rounded-xl"
                  loading="lazy"
                />
              </div>

              {/* Title */}
              <h3 className="mt-6 text-2xl font-semibold text-gray-800 text-center">
                {step.title}
              </h3>

              {/* Description */}
              <p className="mt-4 text-gray-600 text-center">
                {step.description}
              </p>
            </motion.div>
          ))}
        </div>

        {/* Call to Action */}
        <motion.div
          className="text-center mt-20"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1, duration: 0.8 }}
        >
          <a
            href="#"
            className="inline-flex items-center justify-center px-10 py-4 bg-gradient-to-r from-orange-500 to-red-500 text-white text-lg font-semibold rounded-full shadow-lg transform transition-transform duration-300 hover:scale-105 hover:from-orange-600 hover:to-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            aria-label="Start Enhancing Now"
          >
            Start Enhancing Now
            <FaArrowRight className="ml-2" />
          </a>
        </motion.div>
      </div>
    </section>
  );
};

const steps = [
  {
    title: 'Upload Your Image',
    description: 'Drag and drop your photo into our tool. Choose any format, and get started within seconds.',
    icon: <FaUpload />,
    image: "./images/img-inpaint/15a1.webp",
  },
  {
    title: 'Brush Over the Area',
    description: 'Use our simple brush tool to mark the areas you want to enhance. Zoom in for finer details.',
    icon: <FaBrush />,
    image: "./images/img-inpaint/1333.png",
  },
  {
    title: 'Download Your Enhanced Photo',
    description: 'Once you’re satisfied with the result, download your improved image and share it.',
    icon: <FaDownload />,
    image: './images/img-inpaint/15a2.webp',
  },
];

export default HowToEnhanceSection;
