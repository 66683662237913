// FaqSection.jsx

import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('AI Image Inpainting');

  const categories = [
    'AI Image Inpainting',
    'AI Image Restoration',
    'AI Image Upscaling',
    'AI Image Enhancement',
  ];

  const faqs = {
    'AI Image Inpainting': [
      {
        question: 'What is Image Inpainting?',
        answer:
          'Image inpainting is the process of restoring or reconstructing missing parts of an image. Picturify.AI uses advanced AI algorithms to intelligently fill in gaps and remove unwanted elements from photos, making them look complete and natural.',
      },
      {
        question: 'How does Picturify.AI improve damaged photos?',
        answer:
          'Our AI inpainting tool analyzes the surrounding pixels and context in the photo to seamlessly fill in missing or damaged areas, creating a perfect restoration of the original image.',
      },
      {
        question: 'Can I remove objects from my photos?',
        answer:
          'Yes! You can easily remove unwanted objects, watermarks, or even people from your photos. Our AI ensures the area is filled with matching textures and lighting to make it look natural.',
      },
      {
        question: 'Is it possible to restore old and torn photographs?',
        answer:
          'Absolutely. Picturify.AI is designed to restore old and damaged photos by repairing tears, scratches, and other imperfections, bringing your cherished memories back to life.',
      },
    ],
    'AI Image Restoration': [
      {
        question: 'How does Picturify.AI restore old photos?',
        answer:
          'Using deep learning models, our AI carefully restores old or low-quality images by removing blemishes, filling in missing parts, and enhancing overall clarity without compromising the original details.',
      },
      {
        question: 'Can the AI restore color to black-and-white photos?',
        answer:
          'Yes! Picturify.AI can intelligently add color to black-and-white photos, using historical references and AI models to predict and apply realistic colors to your images.',
      },
      {
        question: 'How fast can I restore a damaged image?',
        answer:
          'Image restoration is nearly instant with Picturify.AI. Once you upload the image, our AI works in real-time to restore the photo within seconds, no matter how complex the damage.',
      },
      {
        question: 'Will I lose image quality during restoration?',
        answer:
          'No, Picturify.AI’s algorithms are designed to preserve image quality. The restored version will maintain high resolution while enhancing the clarity and removing defects.',
      },
    ],
    'AI Image Upscaling': [
      {
        question: 'How does Picturify.AI upscale images?',
        answer:
          'Our AI-powered upscaling tool can increase the resolution of your images without losing quality. By predicting and adding missing details, Picturify.AI turns low-resolution images into sharp, high-quality photos.',
      },
      {
        question: 'Can I upscale small images for printing?',
        answer:
          'Yes! Picturify.AI is perfect for upscaling images for printing. You can turn small, pixelated images into crisp, printable versions without any loss in quality.',
      },
      {
        question: 'Does the AI handle upscaling automatically?',
        answer:
          'Yes, the AI automatically analyzes the image and enhances details to upscale it. You don’t need any technical expertise to get great results.',
      },
      {
        question: 'Can I control the upscaling intensity?',
        answer:
          'You can adjust the upscaling settings, allowing you to fine-tune the image quality depending on how much enhancement you require.',
      },
    ],
    'AI Image Enhancement': [
      {
        question: 'What kinds of image enhancements can I do with Picturify.AI?',
        answer:
          'Picturify.AI can enhance brightness, sharpness, and contrast, and even correct colors to make your photos look vibrant and clear. It’s perfect for reviving dull or faded images.',
      },
      {
        question: 'Can I enhance images for social media or websites?',
        answer:
          'Definitely. Picturify.AI optimizes images for both personal and professional use, ensuring they’re ready for sharing on social media or posting on websites in high quality.',
      },
      {
        question: 'Is there a way to adjust the AI-enhanced images manually?',
        answer:
          'After the AI enhances your image, you can fine-tune parameters like contrast, saturation, and sharpness for complete creative control over the final result.',
      },
      {
        question: 'How does the AI maintain the original quality of images during enhancement?',
        answer:
          'The AI works by enhancing details without distorting or losing the original quality of the image. It carefully preserves textures and colors while improving clarity.',
      },
    ],
  };

  const toggleFaq = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <section className="bg-gradient-to-b from-orange-50 to-red-50 py-16 px-4">
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row gap-12">
        {/* Sidebar */}
        <div className="lg:w-1/4">
          <h4 className="text-gray-600 mb-6 uppercase tracking-widest text-lg">Categories</h4>
          <ul className="space-y-4">
            {categories.map((category, index) => (
              <li
                key={index}
                className={`cursor-pointer px-4 py-2 rounded-full transition-colors duration-300 flex items-center justify-between ${
                  category === selectedCategory
                    ? 'bg-gradient-to-r from-orange-500 to-red-500 text-white'
                    : 'bg-white text-gray-700 hover:bg-gradient-to-r hover:from-orange-100 hover:to-red-100'
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
                {category === selectedCategory && (
                  <span className="text-white">
                    <FaChevronUp />
                  </span>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* FAQ Section */}
        <div className="lg:w-3/4">
          <div className="mb-12">
            <h4 className="text-orange-500 text-sm uppercase tracking-widest mb-2">
              Frequently Asked Questions
            </h4>
            <h2 className="text-4xl font-bold text-gray-900">
              Have Questions? <br /> We’ve Got Answers.
            </h2>
          </div>

          {/* Filtered FAQ Content */}
          <div className="space-y-6">
            {faqs[selectedCategory].map((faq, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md">
                <button
                  onClick={() => toggleFaq(index)}
                  className="w-full flex justify-between items-center px-6 py-4 text-left text-gray-800 font-semibold focus:outline-none focus:ring-2 focus:ring-orange-500 transition-colors duration-300"
                  aria-expanded={activeIndex === index}
                  aria-controls={`faq-content-${index}`}
                >
                  <span>{faq.question}</span>
                  {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                </button>
                {activeIndex === index && (
                  <div
                    id={`faq-content-${index}`}
                    className="px-6 py-4 text-gray-600 border-t border-gray-200"
                  >
                    <p className="whitespace-pre-line">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
