// PortfolioSection.jsx

import React, { useState, useEffect, useRef } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { FaTimes } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import FocusTrap from 'focus-trap-react';

const images = [
  {
    before: './images/img-inpaint/10.webp',
    after: './images/img-inpaint/10c.webp',
  },
  {
    before: './images/img-inpaint/6.webp',
    after: './images/img-inpaint/6c.webp',
  },
  {
    before: './images/img-inpaint/5.webp',
    after: './images/img-inpaint/5c.webp',
  },
  {
    before: './images/img-inpaint/9.webp',
    after: './images/img-inpaint/9c.webp',
  },
  {
    before: './images/img-inpaint/13.webp',
    after: './images/img-inpaint/13c.webp',
  },
  {
    before: './images/img-inpaint/2.webp',
    after: './images/img-inpaint/2cc.webp',
  },
  {
    before: './images/img-inpaint/1.webp',
    after: './images/img-inpaint/1c.webp',
  },
  {
    before: './images/img-inpaint/3.webp',
    after: './images/img-inpaint/3c.webp',
  },
  {
    before: './images/img-inpaint/2.webp',
    after: './images/img-inpaint/2c.webp',
  },
];

const PortfolioSection = () => {
  const [modalImage, setModalImage] = useState(null);
  const modalRef = useRef(null);

  const openModal = (image) => {
    setModalImage(image);
    document.body.style.overflow = 'hidden'; // Prevent background scrolling
  };

  const closeModal = () => {
    setModalImage(null);
    document.body.style.overflow = 'auto'; // Restore scrolling
  };

  // Close modal on Esc key press
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, []);

  return (
    <section className="relative py-20 bg-gradient-to-r from-orange-50 to-red-50 overflow-hidden">
      {/* Decorative Elements */}
      <div className="absolute top-0 left-0 w-48 h-48 bg-orange-200 rounded-full mix-blend-multiply opacity-30 filter blur-3xl animate-blob"></div>
      <div className="absolute bottom-0 right-0 w-64 h-64 bg-red-100 rounded-full mix-blend-multiply opacity-30 filter blur-3xl animate-blob animation-delay-2000"></div>

      {/* Main Content */}
      <div className="relative max-w-7xl mx-auto px-6 lg:px-8">
        {/* Heading */}
        <div className="text-center mb-16">
          <motion.h2
            className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Picturify.AI Portfolio: <span className="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-red-500">Before & After</span>
          </motion.h2>
          <motion.p
            className="text-gray-600 mt-4 text-lg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
          >
            Explore how our AI transforms images. Click on any photo for a closer look.
          </motion.p>
        </div>

        {/* Image Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
          {images.map((image, index) => (
            <motion.div
              key={index}
              className="relative group cursor-pointer"
              onClick={() => openModal(image)}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <div className="rounded-lg overflow-hidden shadow-lg">
                <ReactCompareSlider
                  itemOne={<ReactCompareSliderImage src={image.before} alt={`Before Image ${index + 1}`} />}
                  itemTwo={<ReactCompareSliderImage src={image.after} alt={`After Image ${index + 1}`} />}
                  style={{ borderRadius: '0.5rem' }}
                />
              </div>
              {/* Overlay */}
              <motion.div
                className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                initial={{ opacity: 0 }}
                animate={{ opacity: 0 }}
                whileHover={{ opacity: 1 }}
              >
                <span className="text-white text-lg font-semibold">Click to View</span>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Modal */}
      <AnimatePresence>
        {modalImage && (
          <FocusTrap>
            <motion.div
              className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 p-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={closeModal}
            >
              <motion.div
                className="relative max-w-4xl w-full bg-white p-6 rounded-lg shadow-2xl"
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
                transition={{ duration: 0.3 }}
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
              >
                {/* Close Button */}
                <button
                  className="absolute top-0 right-0 text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 rounded-full"
                  onClick={closeModal}
                  aria-label="Close Modal"
                >
                  <FaTimes size={24} />
                </button>

                {/* Image Comparison */}
                <div className="max-h-[80vh] overflow-y-auto">
                  <ReactCompareSlider
                    itemOne={<ReactCompareSliderImage src={modalImage.before} height="50%"
                    alt="Before Image" />}
                    itemTwo={<ReactCompareSliderImage src={modalImage.after} height="50%" alt="After Image" />}
                    style={{ borderRadius: '0.75rem' }}
                  />
                </div>

                {/* Caption */}
                <div className="text-center mt-4 text-gray-500">
                  <span className="text-sm font-semibold">Before</span> vs <span className="text-sm font-semibold">After</span>
                </div>
              </motion.div>
            </motion.div>
          </FocusTrap>
        )}
      </AnimatePresence>
    </section>
  );
};

export default PortfolioSection;
