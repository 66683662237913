// CallToActionSection.jsx

import React from 'react';
import { FaUpload, FaMagic, FaArrowUp } from 'react-icons/fa';
import { motion } from 'framer-motion';

const CallToActionSection = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='p-8 bg-gradient-to-r from-orange-50 to-red-50'>
      <section className="relative rounded-3xl py-20 bg-gradient-to-r from-orange-400 to-red-500 overflow-hidden">
        {/* Decorative Blobs */}
        <div className="absolute -top-16 -left-16 w-72 h-72 bg-white rounded-full mix-blend-multiply opacity-30 filter blur-3xl animate-blob animation-delay-1000"></div>
        <div className="absolute -bottom-24 -right-24 w-96 h-96 bg-white rounded-full mix-blend-multiply opacity-30 filter blur-3xl animate-blob animation-delay-2000"></div>

        {/* Main Content */}
        <div className="relative max-w-7xl mx-auto px-6 lg:px-8 text-center">
          {/* Animated Heading */}
          <motion.h2
            className="text-4xl md:text-5xl font-extrabold text-white mb-6"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Restore Your Photos with{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 to-orange-600">
              Picturify.AI
            </span>
          </motion.h2>

          {/* Animated Subheadline */}
          <motion.p
            className="text-lg md:text-xl text-white mb-12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
          >
            Breathe new life into your memories. Use our AI-powered tools to remove imperfections, fill in missing areas, and enhance your photos with just a few clicks.
          </motion.p>

          {/* Animated Buttons */}
          <motion.div
            className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 0.8 }}
          >
            <a
              href="#upload"
              className="inline-flex items-center justify-center px-8 py-4 bg-gradient-to-r from-yellow-400 to-orange-600 text-white font-semibold rounded-full shadow-lg transform transition-all duration-300 hover:scale-105 hover:from-yellow-500 hover:to-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-300"
              aria-label="Upload Your Photo"
            >
              <FaUpload className="mr-2" />
              Upload Your Photo
            </a>
            <a
              href="#start-restoring"
              className="inline-flex items-center justify-center px-8 py-4 bg-white text-orange-600 font-semibold rounded-full shadow-lg transform transition-all duration-300 hover:scale-105 hover:bg-orange-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-300"
              aria-label="Start Restoring with AI"
            >
              <FaMagic className="mr-2" />
              Start Restoring with AI
            </a>
          </motion.div>
        </div>

        {/* Back-to-Top Button */}
        <button
          onClick={scrollToTop}
          className="hidden md:flex items-center justify-center fixed bottom-8 right-8 bg-white text-orange-500 p-4 rounded-full shadow-lg hover:bg-orange-50 transition-transform transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-orange-300"
          aria-label="Back to Top"
        >
          <FaArrowUp size={20} />
        </button>
      </section>
    </div>
  );
};

export default CallToActionSection;
